import React, {useState} from "react";
import "../App.css";
import links from "../constant/data";
const Search = ({searchRef}) => {
  const [input, setInput] = useState("");
  const [, setStatus] = useState("");
  const [searchObj, setSearchObj] = useState(null);

  const onChange = (e) => {
    const res = checkShortcuts(e.target.value);
    setInput(e.target.value);
    if (res?.search) {
      setSearchObj(res);
    } else if (e.target.value.length < 3) {
      setSearchObj(null);
    }
  };

  const onSubmit = (e) => {
    if (e.key === "Enter") {
      const res = checkShortcuts(input);
      if (res) {
        window.location.href = res.url;
      } else {
        if (searchObj) {
          window.location.href = `${searchObj.url}${input.substr(2)}`;
        } else {
          window.location.href = `http://google.com/search?q=${input}`;
        }
      }
      setInput("");
    }
  };

  const checkShortcuts = (i) => {
    const res = links.find((x) => x.shortcut === i);
    if (res) {
      setStatus(res.name);
    } else {
      setStatus("");
    }
    return res;
  };

  return (
    <div>
      <input
        type="text"
        value={input}
        ref={searchRef}
        placeholder="search something..."
        className="SearchInput"
        onChange={onChange}
        onKeyDown={onSubmit}
        autoFocus
      />
    </div>
  );
};

export default Search;
