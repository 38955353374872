import React, { useState, useRef } from "react";
import "../App.css";
import InfiniteScroll from "react-infinite-scroll-component";
import FadeIn from "react-fade-in";

const Posts = ({ initPost, allPostID }) => {
  const listRef = useRef();
  const [allPost, setAllPost] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchMoreNews = async () => {
    const nextPage = currentPage + 1;
    let morePosts = await Promise.all(
      allPostID
        .slice(currentPage * 50, nextPage * 50)
        .map((id) =>
          fetch(`https://hacker-news.firebaseio.com/v0/item/${id}.json`).then(
            (val) => val.json()
          )
        )
    );
    if (morePosts) {
      console.log("more posts", morePosts);
      setAllPost([...allPost, ...morePosts]);
      setCurrentPage(nextPage);
    }
  };

  const onScroll = () => {
    if (listRef.current) {
      console.log("Reached bottom");

      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log("Reached bottom");
      }
    }
  };

  if (initPost.length > 0) {
    return (
      <div className="PostContainer" onScroll={() => onScroll()} ref={listRef}>
        <FadeIn>
          {initPost.map((data, index) => (
            <div key={index} className="PostItem">
              <a
                className="PostTitle"
                href={`https://news.ycombinator.com/item?id=${data.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.title}
              </a>
              <a
                className="PostLink"
                href={data.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.url}
              </a>
            </div>
          ))}
        </FadeIn>

        <InfiniteScroll
          dataLength={allPost.length}
          next={fetchMoreNews}
          hasMore={true}
          scrollThreshold={0.8}
          loader={<p className="PostTitle">Loading more posts...</p>}
        >
          {allPost.map((data, index) => (
            <div key={index} className="PostItem">
              <a
                className="PostTitle"
                href={`https://news.ycombinator.com/item?id=${data.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.title}
              </a>
              <a
                className="PostLink"
                href={data.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.url}
              </a>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Posts;
