import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "../App.css";

function Clock() {
  const [time, setTime] = useState({
    day: dayjs().format("DD"),
    month: dayjs().format("MMMM"),
    year: dayjs().format("YYYY"),

    hour: dayjs().format("h"),
    minute: dayjs().format("mm"),
  });
  useEffect(() => {
    setInterval(() => {
      setTime({
        day: dayjs().format("DD"),
        month: dayjs().format("MMMM"),
        year: dayjs().format("YYYY"),

        hour: dayjs().format("h"),
        minute: dayjs().format("mm"),
      });
    }, 60000);
  });
  return (
    <div>
      <p
        style={{
          width: "100%",
          textAlign: "center",
          fontFamily: "JetBrains Mono",
          marginBottom: -20,
          fontSize: 14,
        }}
      >
        {time.month} {time.day}
      </p>
      <p
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: 28,
          fontWeight: "bold",
          fontFamily: "JetBrains Mono",
        }}
      >
        {time.hour}:{time.minute}
      </p>
    </div>
  );
}

export default Clock;
