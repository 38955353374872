const links = [
  {
    shortcut: "gm",
    name: "Gmail",
    url: "http://www.gmail.com",
  },
  {
    shortcut: "yt",
    name: "Youtube",
    url: "http://www.youtube.com",
  },
  {
    shortcut: "gl",
    name: "GitLab",
    url: "http://www.gitlab.com",
  },
  {
    shortcut: "ca",
    name: "Calendar",
    url: "https://calendar.google.com/",
  },
  {
    shortcut: "?",
    name: "instructions",
    url: "",
  },
  {
    shortcut: "r/",
    name: "Reddit Search",
    search: true,
    url: "http://reddit.com/r/",
  },
  {
    shortcut: "y ",
    name: "Youtube Search",
    search: true,
    url: "https://www.youtube.com/results?search_query=",
  },
];
export default links;
