import React, { useRef, useState, useEffect } from "react";
import "./App.css";
import SearchInput from "./components/Search";
import Posts from "./components/Posts";
import Clock from "./components/Clock";
function App() {
  const inputRef = useRef(null);
  const [initPost, setInitPost] = useState([]);
  const [allPostID, setAllPostID] = useState([]);
  useEffect(() => {
    inputRef.current.focus();
    const fetchNews = async () => {
      const allPosts = await fetch(
        "https://hacker-news.firebaseio.com/v0/topstories.json"
      ).then((val) => val.json());

      let topHNPosts = await Promise.all(
        allPosts
          .slice(0, 50)
          .map((id) =>
            fetch(`https://hacker-news.firebaseio.com/v0/item/${id}.json`).then(
              (val) => val.json()
            )
          )
      );
      if (topHNPosts) {
        console.log("check all posts", allPosts);
        setAllPostID(allPosts);
        setInitPost(topHNPosts);
      }
    };
    fetchNews();
  }, []);
  return (
    <div className="App">
      <Clock />
      <SearchInput searchRef={inputRef} />
      <Posts initPost={initPost} allPostID={allPostID} />
    </div>
  );
}

export default App;
